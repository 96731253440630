import React, { createContext,useContext } from 'react';
import { useAuthentication, AuthenticationProps } from './auth';

export const AuthContext = createContext({} as AuthenticationProps);


export const AuthProvider: React.FC<{
    children: React.ReactElement
}> = (props) => {
    const auth = useAuthentication();
    const { accessToken } = useContext(AuthContext);
    return (
        <AuthContext.Provider value={auth}>
            {props.children}
        </AuthContext.Provider>
    );
};