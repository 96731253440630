// components/ChatMessage.tsx
import React, { memo, useState, Suspense, lazy } from 'react';
import SQLStatement from './SQLStatement';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import './ChatMessage.css';
import reportIcon from './icons/report.svg';
import resultsIcon from './icons/results.svg';
import codeIcon from './icons/code.svg';
import brainIcon from './icons/brain.svg';
import HistoryIcon from '@mui/icons-material/History';
import OnlySQLStatementTab from './OnlySQLStatementTab';


const TableComponent = lazy(() => import('./TableComponent'));
const ChartComponent = lazy(() => import('./ChartComponent'));

const renderSuggestions = (suggestions: any) => {
  
  if (!Array.isArray(suggestions)) {
    return null; // Or return a fallback UI
  }
  return (
    <ul>
      {suggestions.map((suggestion: any, index: number) => (
        <li className="suggestion-item" key={index}>{suggestion}</li>
      ))}
    </ul>
  );
};

function getMessageClassName(type: string) {
  switch (type) {
      case 'user':
          return 'user-message';
      case 'bot':
          return 'bot-message';
      case 'complete':
          return 'complete-message';
      case 'pre_chat': // Add case for pre_chat
          return 'pre-chat-message'; // Class name for pre_chat messages
      default:
          return '';
  }
}



// Component to render different types of chat messages
const ChatMessage: React.FC<{ message: any,executeSQLStatement:any , handleSubmitWrapper:any }> = memo(({ message ,executeSQLStatement, handleSubmitWrapper}) => {
  const [activeTab, setActiveTab] = useState<'sql' | 'chart' | 'query'>('sql'); // Track active tab

  const handleTabChange = (tab: 'sql' | 'chart' | 'query') => {
    setActiveTab(tab);
  };

  const handleSubmit = handleSubmitWrapper

  if (message.type === 'sql_result') {
    return (
      <div>
        <div className="chat-message-container">
          <br></br>
          <h4>SQL Results and Visualization</h4>
          <br></br>
          {/* Tabs */}
          <div className="tabs">
            <button
              className={`tab ${activeTab === 'sql' ? 'active' : ''}`}
              onClick={() => handleTabChange('sql')}
            >

              <img src={resultsIcon} alt="Data Visualization" className="tab-icon" />
              <span className="tab-text">SQL Result</span>
            </button>

            <button
              className={`tab ${activeTab === 'chart' ? 'active' : ''}`}
              onClick={() => handleTabChange('chart')}
            >

              <img src={reportIcon} alt="Data Visualization" className="tab-icon" />
              <span className="tab-text">Data Visualization</span>
            </button>
            <button
              className={`tab ${activeTab === 'query' ? 'active' : ''}`}
              onClick={() => handleTabChange('query')}
            >

              <img src={codeIcon} alt="Data Visualization" className="tab-icon" />
              <span className="tab-text">SQL Query</span>
            </button>
          </div>
          {/* Tab content */}
          <div className="tab-content">
            {activeTab === 'sql' && (
              <div className="sql-message">
              {/* Check if data exists and if any field has non-empty values */}
              {(message?.data?.length && message.data.some((item:any) => 
                Object.values(item).some(value => value))) ? (
                  <TableComponent columns={message.columns} data={message.data} />
                ) : (
                  <>
                    <TableComponent columns={message.columns} data={message.data} />
                    <p>Based on your input, we generated the SQL query which however did not return any results.Please review the SQL in <strong>SQL Query Tab</strong>.</p>
                  </>
                )
              }
            </div>
            )}

            {activeTab === 'chart' && (
              <div className="chart-message">
                {(!message?.data?.length) ? (
                  <div><p>Based on your input, we generated the query which however did not return any results, click on SQL Query Tab to view the query.</p></div>
                ) : (
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <ChartComponent data={message.data} chartType="bar" metadata={{ width: 900, height: 400 }} />
                  </Suspense>
                )}
              </div>
            )}

            {activeTab === 'query' && (
              <div className="sql-query-message">
                <SQLStatement statement={message.sql} executeSQLStatement={()=>{}}/>
              </div>
            )}

          </div>
        </div>
      </div>
    );
  } else if (message.type === 'suggestions') {
    return (
      <div className="chat-message suggestion-message">
        <h4>Suggestions:</h4>
        {renderSuggestions(message.suggestions)}
      </div>
    );
  }
  else if  (message.type === 'sql_query'){
    return  <OnlySQLStatementTab message={message} executeSQLStatement={executeSQLStatement}/>
      
  }else if (message.type === 'pre_chat') {
    const handleTryItClick = () => {
        const prompt = message.text; // Get the prompt from the message text
        handleSubmitWrapper(null, prompt);
    };

    return (
        <div className={`chat-message ${getMessageClassName(message.type)} fade-in`}>
            <HistoryIcon className="message-icon" color="primary" />
            <span>{message.text}</span>
            <button 
                className="try-it-button" 
                onClick={handleTryItClick} // Call the click handler
            >
                Try it.
            </button>
        </div>
    );
}
 
  return (
    <div className={`chat-message ${getMessageClassName(message.type)}`}>
      {/* Icon Handling */}
      {message.type === 'user' ? (
        <PersonIcon className="message-icon" color="primary" />
      ) : message.type === 'bot' ? (
        <SmartToyIcon color="primary" className="message-icon" />
      ) : message.type === 'complete' ? (
        <div className="icon-text-container">

          <img src={brainIcon} alt="Brain Icon" className="message-icon" />
          <h4>Smart Insights:</h4>
        </div>
      ) : null}

      {/* Message Content */}
      {message.type === 'complete' ? (
        <p>{message.text}</p>
      ) : (
        message.text
      )}
    </div>
  );
});

export default ChatMessage;
