// src/api.ts

// const HOST = "http://localhost:5010"

// export const formatValue = (value: any) => {
//   // First, check if the value is a valid float/number string
//   const parsedValue = parseFloat(value);
  
//   // Check if the value is a number and not an empty string
//   if (!isNaN(parsedValue) && value.trim() !== '') {
//     // Handle values with trailing zeros
//     if (value.includes('.') && /^-?\d+(\.\d*0+)?$/.test(value)) {
//       // Remove trailing zeros and the decimal point if necessary
//       return parsedValue.toString(); // Converts to string without trailing zeros
//     }
//     // Format as float with 2 decimal places if it's not an integer
//     if (!Number.isInteger(parsedValue)) {
//       return parsedValue.toFixed(2); // Format as float with 2 decimal places
//     }
//     // If it's an integer, return it as a string
//     return parsedValue.toString();
//   }

//   // Check if the value is a valid date string (contains recognizable date parts)
//   const date = new Date(value);
//   if (typeof value === 'string' && !isNaN(date.getTime()) && value.includes('-')) {
//     if (value.includes('T') && value.split('T')[1]) {
//       return value; // This retains the format 'YYYY-MM-DDTHH:mm:ss'
//     } else {
//       // If no time part, format to 'YYYY-MM-DD'
//       return date.toLocaleDateString('en-CA'); // 'en-CA' ensures 'YYYY-MM-DD' format
//     }
//   }

//   // Return original value if not a date or number
//   return value;
// };


export const formatValue = (value: any) =>  {
  // Check if the value is a valid date string (contains recognizable date parts with hyphens)
  if (typeof value === 'string' && value.includes('-')) {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      if (value.includes('T') && value.split('T')[1]) {
        return value; // Return original ISO date with time component
      } else {
        return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
      }
    }
  }

  // Parse as float if it’s not a date
  const parsedValue = parseFloat(value);

  // Check if the value is a number and not an empty string
  if (!isNaN(parsedValue) && value.trim() !== '') {
    // Handle values with trailing zeros
    if (value.includes('.') && /^-?\d+(\.\d*0+)?$/.test(value)) {
      return parsedValue.toString(); // Converts to string without trailing zeros
    }
    // Format as float with 2 decimal places if it's not an integer
    if (!Number.isInteger(parsedValue)) {
      return parsedValue.toFixed(2); // Format as float with 2 decimal places
    }
    return parsedValue.toString();
  }

  // Return original value if not a date or number
  return value;
}


export const formatSqlResult = (sqlResult: any[]) => {

  return sqlResult.map((row) => {
    const formattedRow: { [key: string]: any } = {};  // Explicitly typing the object
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        formattedRow[key] = formatValue(row[key]);
      }
    }

    return formattedRow;
  });
};

export const sanitizeString = (inputString: string) => {
  return inputString.replace(/[\'\"\/\\;]/g, "");
}


export const processSQLQuery = (
  sql_query: string, // SQL query string (optional)
  addMessage: any, // Function to add message
) => {
    addMessage({
      type: 'sql_query',
      columns: null,
      data: null,
      sql: sql_query || '', // Use the provided SQL query or fallback to an empty string
    });
}


export const formatSQLQuery = (message: any) => {
  const sqlQuery = message.content
    .map((item: any) => item.statement || '') // Handle completion message
    .filter(Boolean) // Remove empty strings
    .join(' ');
  
  if(sqlQuery){
    return sqlQuery;
  }
  return null;
};

interface SQLResult {
  dynamicColumns: { Header: string; accessor: string; }[];
  formatedata: { [key: string]: any; }[];
}



export const handleSQLResult = (sql_result: any, setColumns: React.Dispatch<React.SetStateAction<any>>): SQLResult | null => {
  if (sql_result && sql_result.length > 0) {
    const keys = Object.keys(sql_result[0]);
    const dynamicColumns = keys.map(key => ({
      Header: key,
      accessor: key
    }));
    
    setColumns(dynamicColumns);
    const formatedata =formatSqlResult(sql_result);

    return {dynamicColumns,formatedata} ;
  }
  return null;
};


export const processSQLResult = (
  sql_result: any, // Raw SQL result
  sql_query: string, // SQL query string (optional)
  setColumns: React.Dispatch<React.SetStateAction<any>>, // Function to set dynamic columns
  addMessage: any, // Function to add message
) => {
  const res: SQLResult | null = handleSQLResult(sql_result, setColumns);
  // Destructure the dynamicColumns and formatedata from the result, if available
  const dynamicColumns = res?.dynamicColumns;
  const formatedata = res?.formatedata;

  // If dynamicColumns are available, add an SQL result message

  addMessage({
    type: 'sql_result',
    columns: dynamicColumns,
    data: formatedata,
    sql: sql_query || '', // Use the provided SQL query or fallback to an empty string
  });
  };
