// src/components/SQLStatement.tsx

import React from 'react';
import './SQLStatement.css';



interface SQLStatementProps {
  statement: string;
  executeSQLStatement: () => void;
  SQLExecuted?: boolean; // Add optional SQLExecuted param, default is false
}

const SQLStatement: React.FC<SQLStatementProps> = ({ statement, executeSQLStatement, SQLExecuted = true }) => {

  const copyToClipboard = () => {
    navigator.clipboard.writeText(statement)
      .then(() => {
        alert('Code copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy code:', err);
      });
  };

  console.log("REDENRING SQL")

  return (
    <div>
      <div className="code-container">
        <button className="copy-button" onClick={copyToClipboard}>
          Copy Code
        </button>
         {/* Conditionally render the Execute Statement button */}
        {!SQLExecuted && (
          <button className="execute-button"  onClick={executeSQLStatement}>Execute SQL</button>
        )}
        
        {statement ? (
          <pre><code>{statement}</code></pre>
        ) : (
          <p>No SQL query found!</p>
        )}
      </div>
      
    </div>
  );
};

export default SQLStatement;
