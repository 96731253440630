// components/SQLStatementTab.tsx
import React from 'react';
import codeIcon from './icons/code.svg';
import SQLStatement from './SQLStatement';

import './ChatMessage.css';

const OnlySQLStatementTab: React.FC<{ message: any,executeSQLStatement:any }> = ({ message,executeSQLStatement }) => {
  // Check if SQL statement exists
  const hasSqlQuery = message.sql;
  

  return (
    <div className="chat-message-container">
      <br />
      <h4>Only SQL Statement</h4>
      <br />
      
      {/* SQL Query Tab */}
      {hasSqlQuery && (
        <div className="tab-content">
          <button className="tab disable">
            <img src={codeIcon} alt="SQL Query" className="tab-icon" />
            <span className="tab-text">SQL Query</span>
          </button>
          <div className="sql-query-message">
            <SQLStatement statement={message.sql} executeSQLStatement={executeSQLStatement} SQLExecuted={false}/>
          </div>
        </div>
      )}
      
      {/* Fallback message if no SQL statement */}
      {!hasSqlQuery && (
        <div className="no-query-message">
          <p>No SQL query available.</p>
        </div>
      )}
    </div>
  );
};

export default OnlySQLStatementTab;
