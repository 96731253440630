import React, { useState, useEffect } from 'react';
import SmartToyIcon from '@mui/icons-material/SmartToy';

interface LoadingMessageProps {
  isLoading: boolean;
}

const LoadingMessage: React.FC<LoadingMessageProps> = React.memo(({ isLoading }) => {
  const [loadingTime, setLoadingTime] = useState<number>(0);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isLoading) {
      setLoadingTime(0); // Reset time when loading starts
      interval = setInterval(() => {
        setLoadingTime(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isLoading]);

  return (
    <div className="chat-message loading-message bot-message">
      <SmartToyIcon color="primary" className="message-icon" />
      ...awaiting response ({loadingTime} seconds)
    </div>
  );
});

export default LoadingMessage;
