import React, { useRef, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress'; 
import './ChatForm.css';

const ChatForm = ({
  handleSubmitWrapper,
  prompt,
  setPrompt,
  fileName,
  setFileName,
  fileOptions,
  isLoading,
  hasStartedChat,
  setHasStartedChat, // New prop
}: {
  handleSubmitWrapper: (e: React.FormEvent<HTMLFormElement>) => void;
  prompt: string;
  setPrompt: (value: string) => void;
  fileName: string;
  setFileName: (value: string) => void;
  fileOptions: string[];
  isLoading: boolean;
  hasStartedChat: boolean; // New prop
  setHasStartedChat: (value: boolean) => void; // New prop
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [prompt]);

  useEffect(() => {
    if (fileOptions.includes('FULFILLMENT_ORDER_ITEMS') && !fileName) {
      setFileName('FULFILLMENT_ORDER_ITEMS');
    }
  }, [fileOptions, fileName, setFileName]);

  // Effect to change hasStartedChat state when fileName changes
  useEffect(() => {
    if (fileName) {
      setHasStartedChat(false); // Set to true when a file is selected
    }
  }, [fileName, setHasStartedChat]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const form = textareaRef.current?.closest('form');
      if (form) {
        form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }
  };

  return (
    <form className="chat-input-container" onSubmit={handleSubmitWrapper}>
      <div className="textarea-container">
        <textarea
          ref={textareaRef}
          placeholder="Type your message here..."
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          onKeyPress={handleKeyPress}
          className="chat-input"
        />
        <IconButton
          type="submit"
          className="submit-button"
          disabled={isLoading}
          aria-label="send"
        >
          {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
        </IconButton>
      </div>
      <select
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
        className="file-select"
        disabled={!fileOptions.length}
      >
        <option value="" disabled>
          {fileOptions.length ? 'Select a file' : 'Loading files...'}
        </option>
        {fileOptions.map((file) => (
          <option key={file} value={file}>
            {file}
          </option>
        ))}
      </select>
    </form>
  );
};

export default ChatForm;
